.container {
  > label {
    display: flex;
    align-items: flex-start;

    > span {
      &:first-child {
        position: sticky;
        top: 0;
        margin-top: 1px;
      }
    }
  }
}