.calendar {
  width: 410px;
  position: absolute;
  right: 0;
  z-index: 999;
  margin-top: 10px;
  border-radius: 14px;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15);

  .datetimeIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    padding: 7px;
    border-radius: 8px;
    background-color: #f4f4f4;

    img {
      width: 100%;
    }
  }

  .tabs {
    padding: 4px;
    min-height: 34px;
    border-radius: 6px;
    background-color: #f4f4f4;

    .scroller {
      overflow: visible !important;
    }

    .indicator {
      display: none;
    }

    .tab {
      min-height: 28px;
      border-radius: 6px;
      color: #000;
      font-size: 14px;
      text-transform: initial;
      font-weight: 600;
      opacity: 1;

      @media (min-width: 600px) {
        min-width: 120px;
        padding: 0 10px;
      }

      &.tabSelected {
        background-color: #ffffff;
        color: var(--primary);
        box-shadow: 0 3px 8px 0 rgba(30, 31, 123, 0.2);
      }
    }
  }

  .buttons {
    button {
      > div {
        padding: 4px 14px;
      }
    }

    .buttonToday {
      > div {
        padding: 4px 0;
      }
    }
  }
}
