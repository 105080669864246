.radio-buttons-group {
  &.mui4-MuiFormGroup-row {
    flex-wrap: nowrap;

    .mui4-MuiFormControlLabel-root {
      &:not(:last-child) {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0
      }

      span {
        font-size: 14px;
      }

      .mui4-MuiButtonBase-root {
        padding: 6px;
        color: #ABABAB;

        & + span {
          color: #ABABAB;
        }

        &.Mui-checked {
          color: var(--primary);

          & + span {
            color: var(--primary);
          }
        }
      }

      .mui4-MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }
  }
}