.fromToTimeUI {
  .hourContainer {
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 5px 15px;
  }
}

.selectWrapper {
  justify-content: center;
  padding: 5px 15px;
  width: 96px;
  border-radius: 6px;
  background-color: #f4f4f4;

  .selectInput {
    padding: 0 !important;
    color: #00003f;
  }

  .selectText {
    color: #000000;
  }
}