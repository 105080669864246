.menuContainer {
  .menu {
    margin-top: 12px;
    padding: 16px 20px;
    border-radius: 14px;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15) !important;

    .filtersFormControlWrapper {
      margin-top: 7px;

      .amenitiesCheckbox {
        label {
          margin: 0;
        }

        &:not(:last-child) {
          label {
            margin: 0 0 7px 0;
          }
        }
      }
    }

    .checked {
      color: var(--primary);
    }
  }

  .buttonBlue {
    > div {
      color: #8e8fbd;
    }
  }

  .buttonDone {
    > div {
      padding: 4px 13px;
    }
  }
}

.count {
  display: inline-block;
  margin-left: 10px;
  padding: 0 6px;
  min-width: 21px;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.attributeLabel {
  font-size: 14px;

  .attributeImage {
    margin: 0 10px 0 5px;
  }
}

.disableClass {
  opacity: .5;
}