.modalInnerContent {
  label {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-bottom: 7px;
  }

  .formErrors {
    color: var(--warn);
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-top: 5px;
  }

  .formErrorShow {
    > div {
      background: var(--warnLight) !important;
    }
  }

  .formMultiErrorShow {
    > div:nth-child(2) {
      background: var(--warnLight) !important;
    }
  }

  .requiredSign {
    color: var(--warn);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
  }
}
