.popoverContent {
  background-color: white;
  box-shadow: 0 5px 30px 0 rgba(0,0,0,0.3);
  border-radius: 14px;
}

.notificationInfiniteScroll {
  max-height: 300px;
  margin-top: 5px;
}

.noNotifications {
  padding: 20px 0;
}

.checkbox {
  margin: 0;
}

.boxSelected {
  background-color: #EFEFF8;
}