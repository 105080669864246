.dashboardItemContainer.dashboardItemContainerRoot {
  display: block;
  min-width: 210px;
  padding: 12px 14px 13px;
  border-radius: 8px;

  &.active {
    background-color: var(--primaryLight);
  }

  .dashboardItemTop {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    > * {
      &:not(:first-child) {
        margin-left: 5px;
      }
    }
  }

  .dashboardItemText {
    font-size: 14px;
  }

  .value {
    font-size: 30px;
  }

  .colorPrimary {
    color: var(--primary);
  }
}