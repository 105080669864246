.wrapper {
  display: flex;
  align-items: center;

  &.fullWidth {
    .selectWrapper {
      width: 100%;
    }

    .selectContainer {
      width: 100%;
    }
  }

  .select.input {
    font-family: "Poppins", sans-serif;

    &.empty {
      > div {
        color: var(--grey);
      }
    }

    &.disabled {
      > div {
        color: var(--greyMedium);
      }
    }

    > div {
      color: var(--primary);
      font-size: 14px;
    }

    .renderValueClass {
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }

    .defaultValueClass {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .selectContainer {
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

    &.selectContainerDisabled {
      & ~ .icon {
        color: var(--greyMedium);
      }
    }
  }

  .inputLabel {
    margin-bottom: 3px;
  }

  .icon {
    position: absolute;
    right: 0;
    transform: translateY(1px);
    color: var(--primary);
  }
}

.selectWrapper {
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    pointer-events: none;
    color: var(--primary);

    svg {
      transform: translateY(1px);
    }
  }
}

.menuPaper {
  padding: 2px 10px;
  border-radius: 14px !important;
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 15%) !important;
}

.menuItemMaterial {
  &.menuItem {
    padding: 7px 5px;
    border-radius: 6px;

    &.menuItemSelect {
      background-color: var(--primaryLight);
    }
  }
}

.checkbox {
  > label {
    margin: 0;
    width: 100%;
    padding: 7px 6px;
    border-radius: 6px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: #EFEFF8;
    }

    > span {
      margin-right: 4px;
    }
  }
}

.attributeLabel {
  font-size: 14px;

  .attributeImage {
    margin-right: 4px;
  }
}
