.menuContainer {
  .menu {
    margin-top: 12px;
    padding: 16px 20px;
    border-radius: 14px;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15) !important;
  
    .checked {
      color: var(--primary);
    }
  }

  .buttonBlue {
    > div {
      color: #8e8fbd;
    }
  }

  .buttonDone {
    > div {
      padding: 4px 13px;
    }
  }
}

.deskTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.skeletonRoot {
  border-radius: 14px;
}

.bookingRightSide {
  max-width: 380px;
}

.instructionList {
  padding: 0 0 0 13px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 22px;

  li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.deskDetailsWrapper {
  padding-top: 10px;
  border-top: 1px solid var(--greyLight);
}
