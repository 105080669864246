.dropzoneLayout {
  &.active {
    .dropzone {
      background-color: #d2d2d2;
    }
  }

  &.reject {
    .dropzone {
      background-color: var(--errorTransparent);
    }
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 60px;
    border: 1px dashed #ababab;
    border-radius: 6px;
    background-color: #f4f4f4;
    cursor: pointer;
    transition: all 0.1s ease-out;

    &.active {
      background: #000;
    }

    &.reject {
      background: blue;
    }
  }

  .previewContainer {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border-radius: 6px;

    &.hide {
      display: none;
    }

    * {
      font-size: 13px;
    }
  }

  .linearProgress {
    height: 5px;
    border-radius: 4px;
    background-color: #ababab;

    .primary {
      background-color: var(--primary);
    }
  }
}