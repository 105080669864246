.reservationHeaderCalendar {
  display: none;

  @media screen and (max-width: 1660px) {
    display: flex;
    align-items: center;
  }

  .calendarDate {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .calendarDateTrigger {
    cursor: pointer;
  }
}
