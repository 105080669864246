.header {
  padding-top: 20px;

  .requiredSign {
    color: var(--warn);
  }

  h3 {
    font-size: 22px;
  }

  .massActionButton {
    padding: 7px 8px 7px 16px;
    border-radius: 8px;
    background: var(--primary);
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    transition: all 0.1s ease-out;
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgb(13 14 76 / 40%);

    &:hover {
      background: var(--primaryHover);
    }

    > div {
      display: flex;
      align-items: center;
    }

    .massActionCount {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      padding: 0 6px;
      min-width: 21px;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      font-size: 14px;
      font-weight: 500;

      .massActionCountClose {
        transition: opacity .1s ease-in;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 8px;
      }

      &:hover {
        .massActionCountClose {
          opacity: 1;
        }
      }
    }
  }
}

.select {
  > div {
    width: 100%;
    background-color: #f4f4f4;
    border-radius: 6px;
  }

  label {
    > div {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .input {
    padding-left: 10px;

    > div {
      font-size: 13px;
    }
  }
}

.materialInput {
  width: 100%;
}

.searchInput {
  width: 230px !important;
  margin-right: 20px !important;
}

.inputLabel {
  cursor: pointer;
}

.checkbox {
  > label {
    margin-right: 0;

    > span {
      padding: 5px;
      font-size: 14px;
      color: #000000;
    }
  }
}
