.uploadFile {
  display: flex;
  align-items: center;
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: var(--primaryHover);
  }
}

.fieldLabel {
  font-size: 14px;
  margin-bottom: 7px;
}

.uploadFileDates {
  .uploadFileDateBox {
    width: 100%;
    font-size: 14px;
  }

  > .uploadFileDateBox:not(:last-child) {
    margin-right: 30px;
  }
}

.inputLabel {
  cursor: pointer;
}

.inputInline {
  > div {
    cursor: pointer;
  }

  input {
    cursor: pointer;
    padding: 7px 10px;
  }
}

.requiredSign {
  color: var(--warn);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 28px;
}
