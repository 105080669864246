.skeletonRoot {
  border-radius: 14px;
}

.floorItem.buttonBase {
  width: 100%;
  height: 90px;
  border-radius: 14px;

  &:hover {
    .container {
      background-color: #eee;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 90px;
    padding: 20px;
    border-radius: 14px;
    background-color: #f4f4f4;
    transition: background-color 0.15s;

    &.selected {
      background-color: var(--primaryLight);
      box-shadow: 0 0 0 1px var(--primary);
    }
  }

  .itemContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}