.header {
  padding-top: 20px;

  h3 {
    font-size: 22px;
  }

  .heading {
    position: relative;
    display: flex;
    align-items: center;

    .headingBack {
      position: absolute;
      left: -25px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .searchInput {
    width: 230px !important;
    font-size: 14px;
  }
}
