.container {
  display: flex;
  align-items: center;
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: var(--primaryHover);
  }
}
