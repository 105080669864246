.leafletSectionName {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 5px 20px 0 rgba(13,14,76,0.2);
  padding: 2px 5px;
  color: #000;
  min-width: 50px;
  max-width: 80px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
