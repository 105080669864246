.leaflet-container {
  .leaflet-draw.leaflet-control {
    display: none;
  }
}

.leaflet-div-icon:not(.leaflet-editing-icon) {
  background: none;
  border: none;
  position: relative;
  transition: opacity .2s ease-out;
  pointer-events: none !important; // allow to select inner items under mark
}

.neighbors-popup {
  .leaflet-popup-content-wrapper {
    margin: 0 !important;
    padding: 0 !important;

    .leaflet-popup-content {
      margin: 0 !important;
      width: auto !important;
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}