.covidQuestionsAction {
  width: 100%;
  min-height: 41px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #F4F4F4;

  .covidQuestionsActionButton {
    display: inline-flex;
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 24px;
    cursor: pointer;

    &.actionButtonActive {
      opacity: .5;
    }
  }

  .covidScheduleReset {
    pointer-events: auto !important;

    > div {
      color: var(--primary) !important;
    }
  }
}

.covidQuestionsList {
  width: calc(100% - 400px);
}
