.checkboxContainer {
  .checkbox {
    &.checked {
      color: var(--primary);
    }
  }

  .emptyLabel {
    display: none;
  }
}
