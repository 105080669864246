.locationNotAvailable {
  color: #5E5E5E;
}

.checkbox {
  > label {
    margin: 0;
    width: 100%;
    padding: 7px 6px;
    border-radius: 6px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: #EFEFF8;
    }

    > span {
      margin-right: 4px;
    }
  }
}

.locationsCheckBoxWrapper {
  max-height: 165px;
  overflow: auto;
}

.attributeLabel {
  font-size: 14px;

  .attributeImage {
    margin-right: 4px;
  }
}

.disabledInputStyle {
  cursor: default !important;
  background-color: transparent !important;

  span {
    display: none !important;
  }
}
