.items {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: 5px -5px 0;

  > .item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 4px 5px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f4f4f4;
  }
}

.backButtonContainer {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;

  .arrowBackIcon {
    font-size: 30px;

    .arrowBackIconFill {
      fill: var(--primary);
    }
  }

  .root {
    height: 30px;
    width: 30px;
    border-radius: 8px;
    background-color: var(--primaryLight);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    > span {
      width: initial;
    }
  }
}

.visitTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}

.stickyBookingWrapper {
  position: relative;

  .stickyBookingRow {
    position: sticky;
    bottom: 0;
    top: 0;
    margin-top: 3px;
    padding: 7px 0 20px;
    background: #fff;
  }

  .stickyBookingLocationRow {
    position: sticky;
    bottom: 0;
    top: 0;
    padding: 7px 0 20px;
    background: #fff;
  }
}
