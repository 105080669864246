.deskDetails {
  .detailsItem {
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 20px;
    align-items: baseline;

    &:not(:first-child) {
      margin-top: 9px;
    }
  }

  .selectFullWidth {
    width: 100%;
  }

  .selectDefault {
    background: #F4F4F4;
    outline: none;
    border-radius: 6px;
    min-height: 36px;
    transition: all 0.3s ease-out;
    padding: 0 6px !important;

    :focus {
      background: #F4F4F4;
    }
  }

  .selectDefaultIcon {
    right: 10px;
  }

  .selectLabel {
    margin: 0;
  }

  .amenitiesItems {
    display: flex;
    flex-direction: column;
    margin: 3px 0 0 2px;

    .checkbox {
      padding: 10px !important;
    }
  }

  .textFieldInput {
    height: 26px !important;
    padding: 0 6px !important;
  }
}
