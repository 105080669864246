.tabs {
  margin-top: 15px;
  padding: 3px;
  min-height: 34px !important;
  border-radius: 6px;
  background-color: #f4f4f4;

  .scroller {
    overflow: visible !important;
  }

  .indicator {
    display: none;
  }

  .tab {
    min-height: auto;
    padding: 2px 12px;
    border-radius: 6px;
    color: #000;
    font-size: 14px;
    text-transform: initial;
    font-weight: 600;
    opacity: 1;

    @media (min-width: 600px) {
      min-width: 142px;
    }

    &.tabSelected {
      background-color: #ffffff;
      color: var(--primary);
      box-shadow: 0 3px 8px 0 rgba(30, 31, 123, 0.2);
    }
  }
}

.skeletonRoot {
  border-radius: 14px;
}
