.deskList {
  margin-top: 20px;

  .tablePagination {
    border: none;
  }

  .select {
    label {
      > div {
        font-size: 13px;
        font-weight: 400;
      }
    }

    .input {
      > div {
        font-size: 13px;
      }
    }
  }

  .checkbox {
    > label {
      margin: 0;

      > span {
        padding: 5px;
        font-size: 13px;
        color: #5e5e5e;
      }
    }
  }

  .tableHead {
    .tableRow {
      background-color: #f4f4f4;

      .tableCell {
        padding: 2px 10px;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 400;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &:nth-child(5) {
          width: 25%;
          max-width: 300px;
        }

        &:nth-child(6) {
          width: 25%;
          max-width: 300px;
        }
      }
    }
  }

  .tableBody {
    .tableRow {
      border-radius: 6px;

      &.tableRowChecked {
        background-color: var(--primaryLight);
      }

      .tableCell {
        padding: 4px 10px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
        color: #000000;
        font-size: 14px;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &:nth-child(5) {
          width: 25%;
          max-width: 300px;
        }

        &:nth-child(6) {
          width: 25%;
          max-width: 300px;
        }

        &.tableCellSmall {
          width: 43px;
        }
      }
    }
  }
}

.checkbox {
  > label {
    margin: 0;
    width: 100%;
    padding: 7px 6px;
    border-radius: 6px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: #EFEFF8;
    }

    > span {
      margin-right: 4px;
    }
  }
}

.attributeLabel {
  font-size: 14px;

  .attributeImage {
    margin-right: 4px;
  }
}
