.tabs-custom {
  button {
    &:not(:last-child) {
      margin-right: 10px;
    }

    > div {
      background: transparent !important;
      box-shadow: none !important;
      padding: 1px 10px !important;
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.22px;
      line-height: 22px;
      transition: all .3s ease-out;
    }

    &:hover {
      > div {
        color: #5E5E5E;
      }
    }

    &.active {
      > div {
        color: var(--primary) !important;
        background: var(--primaryLight) !important;
      }
    }
  }
}