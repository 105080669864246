@import "inputs";
@import "calendars";
@import "leaflet-draw";
@import "radio-buttons";
@import "react-draft-wysiwyg";
@import "react-date-range-custom";
@import "paginations";
@import "tabs";
@import "checkboxes";
@import "tooltips";
@import "popups";
@import "scrollbar";
@import "meeting";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif, "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif, "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
ol {
  margin-inline-start: 1.5em;
}

.app-wrapper {
  &.preload {
    * {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
  }
}

label {
  cursor: pointer;
}

.switch-wrapper {
  position: relative;

  > div {
    position: absolute;
    width: 100%;
  }
}

button {
  cursor: pointer;
}

// required for react-leaflet-draw
// reference: https://github.com/alex3165/react-leaflet-draw#readme
.sr-only {
  display: none;
}

// global reactjs-popup show animation
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

// default modal styles
.modal-overlay {
  background: rgba(0, 0, 0, 0.35);
}

.modal-content {
  min-width: 410px;
  padding: 20px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.3);
}

.modal-admin-calendar-content {
  width: 570px;
}

.modal-inner {
  .modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    h2 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.29px;
      line-height: 28px;
    }

    &__close {
      cursor: pointer;
    }
  }

  &-content {
    &.overflow {
      padding: 0 10px 0 0;
    }
  }
}

fieldset {
  border: none !important;
}