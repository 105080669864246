.covidQuestion {
  .covidQuestionAction {
    transition: all .3s ease-out;
    opacity: 0;
  }

  &:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F4F4F4;
  }

  &:hover {
    .covidQuestionAction {
      opacity: 1;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 28px;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;
  }

  .covidQuestionHeader {
    h3 {
      margin-right: 10px;
    }

    img {
      position: absolute;
      left: -11px;
      top: 1px;
    }

    svg {
      cursor: pointer;

      .fillColor {
        transition: all .3s ease-out;
      }

      &:hover {
        .fillColor {
          fill: var(--primaryLighten20);
        }
      }
    }
  }

  .covidQuestionTextBlock {
    margin-bottom: 10px;
  }

  .covidQuestionText {
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  .covidQuestionActionButton {
    color: var(--primary);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-left: 10px;
  }
}