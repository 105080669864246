.line {
  height: 63px;
}

.group {
  background-color: #F4F4F4;
  border-radius: 14px;
  padding: 5px 10px;
  margin-right: 10px;
  max-width: 180px;
}

.groupSelected {
  @extend .group;
  background-color: #EFEFF8;
  
  & > div, p {
    color: #1E1F7B;    
  }
}