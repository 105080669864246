/* aliasing */

.antialiased {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* fonts */

.sans-serif {
  font-family: 'Poppins', sans-serif;
}

/* sizes */

.font-size-12 {
  font-size: 12px;
  line-height: 18px;
}

.font-size-14 {
  font-size: 14px;
  line-height: 21px;
}

.font-size-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-size-18 {
  font-size: 18px;
  line-height: 28px;
}

.font-size-20 {
  font-size: 20px;
  line-height: 30px;
}

.font-size-22 {
  font-size: 26px;
  line-height: 38px;
}

.font-size-26 {
  font-size: 26px;
  line-height: 38px;
}

.font-size-32 {
  font-size: 32px;
  line-height: 44px;
}

.font-size-38 {
  font-size: 38px;
  line-height: 50px;
}

/* font weight */

.font-weight-regular {
  font-weight: 400;
}

.font-weight-normal {
  font-weight: 500;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

/* emphasis */

.font-style-regular {
  font-style: normal;
}

.font-style-italic {
  font-style: italic;
}

/* decoration */

.text-decoration-line-through {
  text-decoration: line-through;
}

/* overflow */

.break-word {
  word-break: break-word;
}

.no-wrap {
  white-space: nowrap;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* alignment */

html:not([dir='rtl']) .align-left {
  text-align: left;
}

html[dir='rtl'] .align-left {
  text-align: right;
}
html:not([dir='rtl']) .align-right {
  text-align: right;
}

html[dir='rtl'] .align-right {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-justify {
  text-align: justify;
}
