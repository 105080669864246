.userDocumentButtonWrapper {
  overflow: hidden;

  .userDocumentButton {
    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .documentButtonText {
    display: grid;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #000;
    max-width: 95px;
  }
}
