.startCancelDates {
  .startCancelDateBox {
    width: 100%;
    font-size: 14px;
  }

  > .startCancelDateBox:not(:last-child) {
    margin-right: 30px;
  }
}

.fieldLabel {
  font-size: 14px;
  margin-bottom: 7px;
}

.inputInline {
  > div {
    cursor: pointer;
  }

  input {
    cursor: pointer;
    padding: 7px 10px;
  }
}
