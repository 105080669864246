.header {
  padding-top: 20px;
  
  h3 {
    font-size: 22px;
  }

  .heading {
    position: relative;
    display: flex;
    align-items: center;

    .headingBack {
      position: absolute;
      left: -25px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  nav.nav {
    ul {
      display: flex;
      margin-inline-start: 0;

      li {
        list-style-type: none;
        padding: 2px 10px;
        border-radius: 8px;
        background-color: #ffffff;
        transition: all .3s ease-out;

        &:not(:first-child) {
          margin-left: 10px;
        }

        &.isSelected {
          background-color: var(--primaryLight);
        }

        &:hover {
          color: #5E5E5E;
        }
      }
    }
  }

  .searchInput {
    width: 230px !important;

    @media screen and (max-width: 1440px) {
      width: 170px !important;
    }
  }
}

.massActionCounter {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  padding: 0 6px;
  min-width: 21px;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;

  .massActionCountClose {
    transition: opacity .1s ease-in;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 8px;
  }

  &:hover {
    .massActionCountClose {
      opacity: 1;
    }
  }
}

.removeSelection {
  display: flex;
  justify-content: center;
  width: 33px;
  height: 20px;
  margin-left: 6px;
  border-radius: 8px;
  background-color: var(--primaryLight);
  cursor: pointer;
}
