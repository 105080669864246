.notificationTrigger {
  display: flex;
  background: none;
  border: none;

  div {
    display: flex;
    align-items: center;
    position: relative;
  }

  span {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;
  }
}

.newNotificationsExist {
  &:after {
    content: "";
    position: absolute;
    top: -5px;
    right: -2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #FF4C0E;
  }
}

.notificationContentWrapper {
  min-width: 410px;
  padding: 20px 0 20px 20px;
  border-radius: 14px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15);

  h2 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.29px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .notificationContent {
    .notificationInfiniteScroll {
      max-height: 545px;
      overflow: auto;
      padding-right: 20px;

      > div:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .noNotifications {
      padding: 20px 0;
    }
  }
}