.container {
  position: relative;
}

.inputContainer {
  display: flex;
  align-items: center;

  .select {
    > div {
      border-radius: 6px;
    }
  
    label {
      > div {
        font-size: 13px;
        font-weight: 400;
      }
    }
  
    .input {
      padding-left: 10px;
  
      > div {
        color: #000;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}