.profileTableHeader {
  h3 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 28px;
  }
}

.profileTable {
  table-layout: fixed;
}

.tableHead {
  .tableRow {
    background-color: #f4f4f4;

    .tableCell {
      width: 16%;
      padding: 2px 10px;
      border: none;
      color: #5e5e5e;
      font-size: 13px;
      font-weight: 400;
      font-family: "Poppins";

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 25%;
      }
    }
  }
}

.tableBody {
  &.disabled {
    .tableCell {
      color: var(--greyMedium);
    }
  }

  .tableCell {
    width: 16%;
    padding: 7px 10px;
    border: none;
    border-bottom: 1px solid #f4f4f4;
    color: #000000;
    font-size: 14px;
    font-family: "Poppins";

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.documentStatus {
  color: #000;

  svg {
    margin-right: 7px;
  }
}
