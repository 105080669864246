.allSelect {
  margin: 10px 0 -10px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 22px;

  .allSelectActivate {
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
    margin: 0 5px;
  }
}
