.bookingDetails {
  h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
  }

  label {
    font-size: 14px;
  }

  .disabledBlock {
    pointer-events: none;
    color: #ABABAB;

    input {
      color: #ABABAB;
    }
  }

  .requiredSign {
    color: var(--warn);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
  }

  .formErrors {
    color: var(--warn);
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-top: 5px;
  }

  .formErrorShow {
    > div {
      background: var(--warnLight) !important;
    }
  }

  .inputInline {
    > div {
      cursor: pointer;
    }

    input {
      cursor: pointer;
      padding: 7px 10px;
    }
  }
}