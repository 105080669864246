.tooltipContent {
  font-size: 14px;
  font-weight: 400;
}

.tooltipContentBox {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
}

.editSetting {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 7px;
  cursor: pointer;

  .fillColor {
    transition: all .3s ease-out;
  }

  &:hover {
    .fillColor {
      fill: var(--primaryLighten20);
    }
  }
}