.numberContainer {
  display: flex;
  align-items: center;
  height: 22px;
  width: 22px;
  border-radius: 8px;
  justify-content: center;
}

.line {
  height: 1px;
  width: 20px;
  background-color: #A9A9F4;

  &.big {
    width: 30px;
  }
}

.locationList {
  position: relative;

  .stickyButtonRow {
    position: sticky;
    bottom: 0;
    margin-top: 3px;
    padding: 17px 0 20px;
    background: #fff;
  }
}

.items {
  display: grid;
  gap: 15px;
}