.checkbox-custom {
  &.mui4-MuiCheckbox-root {
    padding: 2px;
    margin-right: 5px;

    &:not(.Mui-checked) {
      svg {
        g {
          transition: stroke .1s ease-out;
        }
      }

      &:hover {
        svg {
          g {
            stroke: #5E5E5E;
          }
        }
      }
    }
  }

  .mui4-MuiSvgIcon-root {
    width: 22px;
    height: 22px;
  }
}


// add hover state for checkbox in form control
.mui4-MuiFormControlLabel-root {
  &:hover {
    .checkbox-custom {
      &.mui4-MuiCheckbox-root {
        &:not(.Mui-checked) {
          svg {
            g {
              transition: stroke .1s ease-out;
              stroke: #5E5E5E;
            }
          }
        }
      }
    }
  }
}
