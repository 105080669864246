.weekDaysContainer {
  display: flex;
  margin-top: 10px;

  > div {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .hourContainer {
    background-color: var(--primaryLight);
    border-radius: 6px;
    padding: 5px 15px;

    &.selected {
      background-color: #f4f4f4;
    }
  }
}
