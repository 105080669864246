.dialogContainer {
  .dialog {
    padding: 20px;
    border-radius: 14px;
  }

  .inputLabel {
    cursor: pointer;
  }
}
