.scrollComponent {
  max-height: 180px;

  > div {
    box-shadow: none;
    margin: 0;

    ul {
      padding: 0;
      max-height: 180px;

      li {
        padding-left: 5px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        &[aria-selected="true"] {
          background-color: #EFEFF8;
          color: #1E1F7B;
        }
      }
    }
  }
}

.locationSearch {
  margin-top: 5px !important;
}

.checkedLabel {
  background-color: #EFEFF8;
}

.checked {
  color: var(--primary);
}

.searchInput {
  input {
    padding: 7px 10px 7px 5px !important;
  }
}

.formErrorShow {
  > div {
    background: var(--warnLight) !important;
    > div {
      background: var(--warnLight) !important;
    }
  }
}
