.accordionCounter {
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--primaryLight);
  cursor: pointer;

  &:hover {
    background-image: url("assets/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
  }
}

.ownerSearch {
  margin-top: 5px !important;

  input {
    text-overflow: ellipsis;
  }
}

.expandIcon {
  cursor: pointer;
}

.expandIconOpen {
  transform: rotate(-180deg);
}
