.menuContainer {
  .menu {
    margin-top: 12px;
    padding: 16px 20px;
    border-radius: 14px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15) !important;

    .checked {
      color: var(--primary);
    }
  }

  .buttonBlue {
    > div {
      color: #8e8fbd;
    }
  }

  .buttonDone {
    > div {
      padding: 4px 13px;
    }
  }
}

.visitTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}

.skeletonRoot {
  border-radius: 14px;
}

.bookingRightSide {
  max-width: 380px;
}

.container {
  ol {
    li {
      &:not(:first-child) {
        margin-top: 21px;
      }
    }
  }
}
