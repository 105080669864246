:root {
  --color-blue: var(--primary);
  --color-darkGrey: var(--greyDark);
  --color-grey: var(--grey);
  --color-green: var(--success);
  --color-lightGrey: var(--greyMedium);
  --color-orange: var(--warn);
  --color-orange-light: var(--warnLight);
  --color-red: var(--error);

  --color-blue-transparent: var(--primaryTransparent);
  --color-darkGrey-transparent: var(--greyDarkTransparent);
  --color-grey-transparent: var(--greyTransparent);
  --color-green-transparent: var(--successTransparent);
  --color-lightGrey-transparent: var(--greyLightTransparent);
  --color-red-transparent: var(--errorTransparent);
}

.black {
  color: #000000;
}
.blue {
  color: var(--color-blue);
}
.dark-gray {
  color: var(--color-darkGrey);
}
.gray {
  color: var(--color-grey);
}
.green {
  color: var(--color-green);
}
.light-gray {
  color: var(--color-lightGrey);
}
.orange {
  color: var(--color-orange);
}
.orange-light {
  color: var(--color-orange-light);
}
.red {
  color: var(--color-red);
}
.white {
  color: #ffffff;
}

.border {
  border-style: solid;
  border-width: 1px;

  &.border-color-black {
    border-color: #000000;
  }

  &.border-color-blue {
    border-color: var(--color-blue-transparent);
  }

  &.border-color-dark-gray {
    border-color: var(--color-darkGrey-transparent);
  }

  &.border-color-gray {
    border-color: var(--color-grey-transparent);
  }

  &.border-color-green {
    border-color: var(--color-green-transparent);
  }

  &.border-color-light-gray {
    border-color: var(--color-lightGrey-transparent);
  }

  &.border-color-red {
    border-color: var(--color-red-transparent);
  }
}
