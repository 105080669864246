.tooltip {  
  display: inline-block;  
  cursor: pointer;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.tooltipText {
  visibility: hidden;  
  background-color: #808080;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;  
  z-index: 1;  
  font-family: Poppins semi-bold;
  font-size: 12px;
  position: absolute;
  left: 22px;
  top: 45px;
  transition: visibility 0.25s ease;
}