.heading {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 44px;

  .headingBack {
    position: absolute;
    left: -25px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}