.filtersFormControlWrapper {
  margin-top: 7px;

  label {
    margin: 0;
    padding: 6px 5px;
    height: 34px;
    border-radius: 6px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: #F4F4F4;
    }

    > span {
      margin-right: 7px;
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;
    }
  }
}

.locationSearch {
  margin-top: 5px !important;
}

.checkedLabel {
  background-color: #EFEFF8;
}

.checked {
  color: var(--primary);
}