.popoverContent {
  display: flex;
  overflow: auto;
  gap: 20px;
  height: calc(100vh - 40px);
  padding: 16px 20px;

  background-color: white;
  box-shadow: 0 5px 30px 0 rgba(0,0,0,0.3);
  border-radius: 14px;

  .roomImages {
    width: 65%;
  }

  .roomDetails {
    width: 35%;
  }
}

.notificationInfiniteScroll {
  max-height: 240px;
  overflow: auto;  
  margin-top: 5px;

  > div:not(:last-child) {
    padding: 10px 0px;
  }
}

.noNotifications {
  padding: 20px 0;
}

.checkbox {
  margin: 0;
}

.text {
  display: flex;
  align-items: center;
}

.boxSelected {
  background-color: #EFEFF8;
}

.roomDetailsBox {
  background-color: #F4F4F4;
  border-radius: 8px;
  padding: 8px 8px;
  align-items: center;

  > div {
    line-height: 1;
  }
}

.selectRoomButton {
  align-self: flex-start;
}

.closeIcon {
  cursor: pointer;
}