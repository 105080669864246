.stickyBookingWrapper {
  position: relative;

  .stickyBookingRow {
    position: sticky;
    bottom: 0;
    top: 0;
    margin-top: 3px;
    padding: 7px 0 20px;
    background: #fff;
  }

  .stickyBookingLocationRow {
    position: sticky;
    bottom: 0;
    top: 0;
    padding: 7px 0 20px;
    background: #fff;
  }
}
