.notificationForm {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #F4F4F4;

  &.notificationFormEdit {
    border-bottom: none;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-bottom: 7px;
  }

  .formErrors {
    color: var(--warn);
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-top: 5px;
  }

  .formErrorShow {
    > div {
      background: var(--warnLight) !important;
    }
  }

  .requiredSign {
    color: var(--warn);
  }

  .inputInline {
    input {
      padding: 7px 10px;
    }
  }

  .inputSubject {
    width: 100%;
  }

  .inputDatesWrapper {
    > div:not(:first-child) {
      margin-left: 30px;
    }
  }

  .inputDate {
    position: relative;
    width: 270px;
  }

  .buttonsBlock {
    margin: 20px 0 10px;

    > button {
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}