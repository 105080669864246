.groups {
  .groupsTable {
    table-layout: auto;
  }

  .tableHead {
    .tableRow {
      background-color: #f4f4f4;

      .tableCell {
        padding: 2px 10px;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 400;
        font-family: "Poppins";

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .tableBody {
    .tableRow {
      &:hover {
        border: none;
      }

      td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      .tableCell {
        padding: 16px 10px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
        color: #000000;
        font-size: 14px;
        font-family: "Poppins";
      }
    }

    .pointerRow {
      cursor: pointer;
    }

    .checkedRow {
      background: var(--primaryLight);

      .tableCell {
        color: var(--primary);
      }
    }
  }

  .tableHead, .tableBody {
    .tableRow {
      .tableCell {
        width: 16%;

        &:nth-child(1) {
          width: 20%;
          max-width: 200px;
        }

        &:nth-child(2) {
          width: 50%;
          max-width: 500px;
        }

        &:nth-child(3) {
          width: 15%;
          max-width: 150px;
        }

        &:nth-child(4) {
          width: 15%;
          max-width: 150px;
        }
      }
    }
  }
}
