.covidScheduleHeader {
  margin-top: -8px;

  svg {
    margin-right: 10px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 28px;
    margin-right: 10px;
  }
}

.covidScheduleTimes {
  h4 {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;
  }

  .covidScheduleTimesBlock {
    margin-bottom: 10px;
  }

  .covidScheduleTimesText {
    color: #5E5E5E;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  .covidScheduleTimesInputs {
    width: 115px;
  }

  .covidScheduleTimesInput {
    width: 70px;
    margin-right: 7px;

    input {
      margin-left: 17px;
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 25px;
      color: #000000;
    }
  }
}