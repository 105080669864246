.rdw-editor-wrapper {
  &.theme-blue {
    .rdw-option-wrapper {
      &:hover,
      &.rdw-option-active {
        > img {
          filter: invert(11%) sepia(82%) saturate(3124%) hue-rotate(237deg) brightness(86%) contrast(101%);
        }
      }
    }
  }

  &.theme-green {
    .rdw-option-wrapper {
      &:hover,
      &.rdw-option-active {
        > img {
          filter: invert(44%) sepia(96%) saturate(613%) hue-rotate(133deg) brightness(96%) contrast(103%);
        }
      }
    }
  }
  
  .rdw-option-wrapper {
    min-width: 0;
    padding: 1px;

    &:hover {
      box-shadow: 0px 0px 3px 0px #bfbdbd;
    }
  }

  .rdw-option-active {
    box-shadow: 0px 0px 3px 0px #bfbdbd;
  }

  .public-DraftStyleDefault-block {
    margin: 0.1em 0;
  }
}
