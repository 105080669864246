.instructionBlock {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .instructionBlockImage {
    margin: 3px 10px 0 0;
  }
}
