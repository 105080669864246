// rewrite lib styles with !important
.overflow-tooltip {
  border-radius: 6px !important;
  background-color: #000000 !important;
  color: #fff !important;
  box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15) !important;
  font-family: 'Poppins', sans-serif, "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 7px 8px !important;

  &.mui4-MuiTooltip-tooltipPlacementTop {
    margin: 5px 0 !important;
  }
}