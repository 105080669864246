.userManagementTable {
  table-layout: fixed;
}

.tableHead {
  .tableRow {
    background-color: #f4f4f4;

    .tableCell {
      padding: 2px 10px;
      border: none;
      color: #5e5e5e;
      font-size: 13px;
      font-weight: 400;
      font-family: "Poppins";

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.tableBody {
  .tableRow {
    &:hover {
      border: none;
    }

    td:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    td:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .tableCell {
      padding: 9px 10px;
      border: none;
      border-bottom: 1px solid #f4f4f4;
      color: #000000;
      font-size: 14px;
      font-family: "Poppins";
    }

    .statusBooked {
      color: #5BC535;
    }

    .statusCancelled {
      color: var(--warn);
    }

    .statusPassed {
      color: #5E5E5E;
    }

    .statusPending {
      color: #5E5E5E;
    }
  }

  .checkedRow {
    background: var(--primaryLight);

    .tableCell {
      color: var(--primary);
    }
  }

  .selectFullWidth {
    width: 100%;
  }

  .rolePadding {
    padding-right: 0;
  }

  .selectWrapper {
    width: 100%;
  }

  .selectLabel {
    margin: 0;
  }

  .selectText {
    color: #000;
    font-weight: 400;
  }

  .locationNotAvailable {
    color: #5E5E5E;
  }

  .disableRow {
    color: var(--greyMedium);
    div {
      color: var(--greyMedium);
    }
  }
}

// column sizes
.listView {
  .allSelect {
    margin: -10px 0 10px 0;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;

    .allSelectActivate {
      color: var(--primary);
      font-weight: 600;
      cursor: pointer;
      margin: 0 5px;
    }
  }

  .tableHead {
    .tableRow {
      .tableCell {
        width: 16%;

        &:last-child {
          width: 3%;
        }

        &:nth-child(1) {
          width: 16%;
        }

        &:nth-child(2) {
          width: 16%;
        }

        &:nth-child(3) {
          width: 14%;
        }

        &:nth-child(4) {
          width: 25%;
        }
      }
    }
  }
}
