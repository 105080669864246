.customGroup {
  display: flex;
  flex-direction: column;
  line-height: 30px;

  .groupTitle {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.customItem {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.timer {
  display: flex;
  flex-direction: column;
  width: 30px;
  align-items: center;
}

.day {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.time {
  height: 30px;
}

.block {
  border-bottom: 1px solid #eee;
  width: 100%;
  cursor: pointer;  

  &:hover {
    background-color: #EFEFF8;
    border-radius: 6px;
  }
}

.blockSelected {
  width: 100%;
  cursor: pointer;
  background-color: #EFEFF8; 
}

.blockFirstSelected {
  @extend .blockSelected;
  border-radius: 6px 6px 0px 0px; 
}

.blockLastSelected {
  @extend .blockSelected;
  border-radius: 0px 0px 6px 6px; 
}

.blockScheduled {
  width: 100%;
  background-color: #FFE4DB;    
}

.blockFirstScheduled {
  @extend .blockScheduled;
  border-radius: 6px 6px 0px 0px;  
}

.blockLastScheduled {  
  @extend .blockScheduled;
  border-radius: 0px 0px 6px 6px;  
}

.blockDisabled {
  @extend .blockScheduled;
  background-color: #eee;
  border-radius: 6px;    
}

.list {
  height: 300px;
  overflow-y: scroll;
}

.calendar {
  overflow: hidden;
}

.headerText {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  line-height: 28px !important;
  color: #ABABAB !important;
  margin-bottom: 5px;
}

.dateSelector {
  border-top: 1px solid #F4F4F4;
}

.dateRange {
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
}