.container {
  position: relative;
  min-height: 100vh;
  max-width: 800px;
  margin: 20px auto;

  > * {
    margin: 10px 0;
  }
}
