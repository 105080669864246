form.form {
  min-width: 800px;

  .inputLabel {
    cursor: pointer;
  }

  .whenToSendButton {
    justify-content: flex-start;
    width: 100%;
    min-width: 196px;

    > div {
      width: 100%;
      padding: 7px 10px;
      background-color: var(--greyLight);
      color: var(--grey);
      font-weight: 500;
      text-align: left;
      box-shadow: none;

      &:hover {
        background: var(--greyLight);
      }
    }
  }
  
  .formErrors {
    color: var(--warn);
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-top: 5px;
  }
}

.calendarContainer {
  width: 410px;
  padding: 20px 30px;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    button {
      > div {
        padding: 4px 14px;
      }
    }

    .buttonToday {
      > div {
        padding: 4px 0;
      }
    }
  }
}
