.covidNewQuestion {
  h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-bottom: 7px;
  }

  .questionCovidStatusText {
    color: #5e5e5e;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-right: 20px;
  }
}
