.floorMap {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  margin-top: 20px;

  .checkbox {
    display: flex;

    > label {
      margin-right: 0;

      > span {
        font-size: 14px;
      }
    }
  }

  .mapControl {
    padding: 1px 5px;
  }

  .mapControlActive {
    border-radius: 8px;
    padding: 1px 5px;
    background-color: #EFEFF8;
  }
}
