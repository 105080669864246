.items {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: 0 -5px;

  > .item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 4px 5px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f4f4f4;
  }
}

.deskStatus {
  margin: 25px 0;

  .deskStatusRow {
    display: flex;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    & > :first-child {
      width: 160px;
      color: #5E5E5E;
    }

    & > :nth-child(2) {
      color: #000;
    }

    .statusColor {
      color: #5BC535;
    }
  }
}
