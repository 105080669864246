.menu {
  height: 100%;
  padding: 20px 15px;
  background-color: var(--primaryLight);;

  .menuItems {
    > * {
      &:not(:first-child) {
        margin-top: 5px;
      }
    }

    .text {
      transition: all .3s ease-out;

      &:hover {
        color: #5E5E5E;
      }
    }
  }

  nav.nav {
    width: 100%;
    padding-left: 35px;

    ul {
      margin-inline-start: 0;

      li {
        padding: 2px 0;
        list-style-type: none;
      }
    }
  }

  .accordion {
    background: none;
    box-shadow: none;

    &:before {
      display: none;
    }

    &.accordionExpanded {
      margin: 0;
      margin-top: 5px;
    }

    .accordionSummary {
      min-height: 30px;
      padding: 0 5px;
      border-radius: 8px;

      &.accordionSummaryActive {
        background-color: #e3eef8;

        .accordionSummaryIcon {
          margin-right: 0;
          padding: 0;

          svg {
            color: var(--primary) !important;
          }
        }

        .mapIcon,
        .text {
          color: var(--primary);

          &:hover {
            color: #5E5E5E;
          }
        }
      }

      .accordionSummaryContent {
        min-height: 30px;
        display: flex;
        align-items: center;
        margin: 0;

        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }

      .accordionSummaryIcon {
        margin-right: 0;
        padding: 0;

        svg {
          color: #000000 !important;
        }
      }
    }

    .accordionDetails {
      justify-content: center;
      padding: 3px 0;

      .menuItem {
        position: relative;

        a {
          display: flex;
          align-items: center;
          width: 100%;

          button {
            width: 100%;
            justify-content: flex-start;
          }
        }

        &.menuItemSelected {
          &:before {
            content: "";
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 10px;
            height: 4px;
            width: 4px;
            background-color: var(--primary);
            border-radius: 50%;
          }
        }
      }
    }
  }

  .menuLink {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 2px 5px;
    border-radius: 8px;

    &.menuLinkActive {
      background-color: #e3eef8;
      color: var(--primary);
    }
  }

  .reservationActive {
    rect {
      fill: var(--primary);
    }
  }

  .appointmentsActive {
    fill: var(--primary);
  }

  .settingsActive {
    circle {
      fill: var(--primary);
    }
  }

  .sendNotificationActive {
    polygon {
      fill: var(--primary);
    }
  }

  .userManagementActive {
    rect {
      fill: #ffffff;
    }

    .userManagementCircle {
      stroke: transparent;
      fill: var(--primary);
    }
  }

  .groupsActive {
    rect {
      fill: var(--primary);
    }
  }
}

.tooltip {
  padding: 8px 14px;
  border-radius: 6px;
  background-color: #000000;
  font-size: 14px;
}
