.notification {
  padding: 15px 20px;
  border-radius: 14px;
  background-color: #F4F4F4;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 24px;

  .notificationSubject {
    font-weight: 600;
  }

  .notificationMessage {
    margin-top: 10px;
    color: #5E5E5E;
  }

  .notificationSystemInfo {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px solid #E5E5E5;
    color: #5E5E5E;

    .notificationSystemInfoDesk {
      color: #000;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .notificationLocationName {
      max-width: 140px;
    }

    .notificationFloorName {
      max-width: 70px;

      img {
        margin-right: 5px;
      }
    }

    .notificationSectionName {
      max-width: 90px;

      img {
        margin-right: 5px;
      }
    }
  }
}