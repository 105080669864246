.tooltipContent {
  font-size: 14px;
  font-weight: 400;
}

.tooltipContentBox {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
}
