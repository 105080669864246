.numberInput {
  width: 34px;
  height: 24px !important;

  input {
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.38px;
    line-height: 25px;
    color: #000000;
  }
}

.inputLabel {
  margin-right: 8px;
  cursor: pointer;
}