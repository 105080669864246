.pagination-custom {
  .mui4-MuiPaginationItem-root {
    height: 36px;
    min-width: 36px;
    border-radius: 10px;
    margin: 0;
    font-size: 16px;
    font-family: 'Poppins', sans-serif, "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;

    &.Mui-selected {
      background-color: var(--primaryLight);
      color: var(--primary);
      font-weight: bold;
    }
  }

  .mui4-MuiPaginationItem-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}