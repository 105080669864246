.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 0;

  .content {
    display: grid;
    gap: 30px;
    grid-template-columns: 8fr 4fr;
    padding-bottom: 50px;
  }

  .items {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin: 0 -5px;

    > .item {
      margin: 4px 5px;
      padding: 4px 8px;
      border-radius: 8px;
      background-color: #f4f4f4;
    }
  }

  .infoContainer {
    margin-top: 20px;
  }

  @media screen and (max-width: 1100px) {
    .content {
      grid-template-columns: 1fr;
    }

    .infoContainer {
      display: flex;
    }
  }
}
