.weekDaysContainer {
  display: grid;
  grid-gap: 10px;
  gap: 10px 13px;
  grid-template-columns: 1fr 1fr 1fr;

  .hourContainer {
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 5px;

    &.selected {
      background-color: var(--primaryLight);
    }
  }

  .slotButton {
    width: 100%;
  }
}
