.day {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.headerText {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  line-height: 28px !important;
  color: #ABABAB !important;
  margin-bottom: 5px;
}

.currentDay {
  color: #1E1F7B;
};

.selectedDay {
  color: #1E1F7B !important;
  background: #EFEFF8;
  padding: 0px 22px;
  border-radius: 6px;
  font-weight: bold !important; 
}