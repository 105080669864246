.accordion {
  width: 100%;
  margin-top: 0 !important;
  background: none !important;
  box-shadow: none !important;

  &:before {
    display: none;
  }

  &.accordionExpanded {
    margin: 5px 0 0;

    .accordionSummary {
      min-height: 30px;
    }

    .accordionSummaryIcon {
      transform: rotate(-90deg);
    }
  }

  .accordionSummary {
    width: 100%;
    display: inline-flex;
    min-height: 30px;
    padding: 0;
    border-radius: 8px;
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;

    &.accordionSummaryActive {
      background-color: #e3eef8;

      .accordionSummaryIcon {
        margin-right: 0;
        padding: 0;

        svg {
          color: var(--primary) !important;
        }
      }
    }

    .accordionSummaryContent {
      min-height: 30px;
      display: flex;
      align-items: center;
      margin: 0;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .accordionSummaryIcon {
      margin-right: 0;
      margin-left: 5px;
      padding: 0;

      svg {
        width: 20px;
      }
    }
  }

  .accordionDetails {
    padding: 0;
    color: #5E5E5E;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }
}