.button {
  padding: 7px 45px;
  border-radius: 8px;
  background: var(--primary);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.1s ease-out;
  cursor: pointer;

  &.clear {
    background: none;
    color: var(--primary);

    &:hover {
      color: var(--primaryHover);
    }

    &.disabledStyle {
      pointer-events: auto !important;
      opacity: 0.5;
    }

    &.shadow {
      box-shadow: none;
    }
  }

  &.primary {
    background: var(--primary);

    &:hover {
      background: var(--primaryHover);
    }

    &.disabledStyle {
      pointer-events: auto !important;
    }
  }

  &.secondary {
    background: var(--secondary);
  }

  &.white {
    background: #ffffff;
    color: var(--primary);
  }

  &.black {
    background: #000000;
    color: #ffffff;
  }

  &.shadow {
    box-shadow: 0 5px 20px 0 rgba(13, 14, 76, 0.4);
  }

  &.sm {
    padding: 7px 16px;
  }

  &.sm130 {
    width: 130px;
    padding: 7px 16px;
  }

  &.xs {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 5px 9px;
  }

  &.xsm {
    padding: 3px 6px;
  }

  &.md {
    padding: 16px 70px;
  }

  &.full {
    width: 100%;
    padding: 7px 16px;
  }

  &.noPaddingX {
    padding: 7px 0;
  }
}

.buttonContainer {
  background: none;
  border: none;

  &:disabled {
    cursor: not-allowed !important;

    .button {
      color: #ffffff75;
      cursor: not-allowed;

      &.clear {
        color: var(--primary);
        background: none;
        opacity: 0.5;
      }

      &.white {
        background: #ffffff;
        color: rgba(30, 31, 123, 0.5);
      }
    }
  }
}

.buttonBase {
  outline: initial !important;
  border-radius: 8px !important;

  &.noOutlineOnFocus {
    &:focus {
      box-shadow: none;
    }
  }

  &.full {
    width: 100%;
  }
}
