.emailTemplate {
  &:first-child {
    .accordion {
      border-top: 1px solid var(--greyLight);
    }
  }

  .accordion {
    box-shadow: none;
    border-bottom: 1px solid var(--greyLight);
  }

  .summary {
    padding: 0;

    &.expanded {
      display: none;
    }
  }

  .details {
    display: block;
    padding: 0 0 16px;
  }

  .editorWrapper {
    min-height: 400px;
  }
}
