.dashboardContainer {
  margin-top: 20px;

  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 300px;
    grid-template-areas:
      'month day'
      'domain domain'
    ;
    column-gap: 40px;
    row-gap: 50px;
    margin-top: 60px;

    > * {
      align-self: flex-end;
    }

    .month {
      grid-area: month;
    }
    
    .day {
      grid-area: day;
    }
    
    .domain {
      grid-area: domain;
    }
  }
}
