.menuWrapper {
  border-right: 1px solid #F4F4F4;

  nav.nav {
    width: 115px;

    ul {
      display: block;
      margin-inline-start: 0;

      li {
        list-style-type: none;
        padding: 2px 10px;
        border-radius: 8px;
        background-color: #ffffff;
        transition: all .3s ease-out;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &.isSelected {
          background-color: var(--primaryLight);
        }

        &:hover {
          color: #5E5E5E;
        }
      }
    }
  }
}

.templateWrapper {
  width: 100%;
  padding-left: 20px;
}
