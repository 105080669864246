.editFloorMapTitle {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.35px;
  line-height: 28px;
  margin-right: 20px;
}

.editFloorMapSubTitle {
  margin-top: 2px;
}

.lastButton {
  margin-left: 15px;
}
