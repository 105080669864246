.yearLine {
  border-radius: 6px;
  background-color: #F4F4F4;
  padding: 3px 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.25px;

  button {
    > div {
      padding: 0 !important;
    }
  }
}

.availablePopupWrapper {
  z-index: 999;

  .availablePopupAnimate {
    box-shadow: none;

    .availablePopupContent {
      width: 220px;
      padding: 20px;
      border-radius: 14px;
      background-color: #FFFFFF;
      box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15);

      .availablePopupClose {
        cursor: pointer;
      }

      .availablePopupDate {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: 28px;
      }

      .availablePopupSwitch {
        color: #5E5E5E;
        font-size: 13px;
        letter-spacing: 0.21px;
        line-height: 22px;
      }

      .availablePopupSwitchLabel {
        margin: 0 0 0 8px;
      }
    }
  }
}
