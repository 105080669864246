.previousButton, .nextButton {
  background-repeat: no-repeat;
  background-position: center;

  svg {
    display: none;
  }
}

.previousButton {
  background-image: url('assets/arrow-left.svg');
}

.nextButton {
  background-image: url('assets/arrow-right.svg');
}