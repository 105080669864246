.container {
  .switchLabel {
    font-family: "Poppins";
    font-weight: 600;
  }

  .selectContainer {
    background-color: var(--greyLight);
    border-radius: 6px;
    
    .input {
      padding-left: 10px;
      padding-right: 3px;
    }
  }
}
