.noMap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5E5E5E;
  font-size: 14px;
}

.skeletonRoot {
  border-radius: 14px;
}

.deskTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
