.sectionDetails {
  .detailsSection {
    display: grid;
    grid-template-columns: 102px 1fr;
    gap: 20px;
    align-items: center;

    &:not(:first-child) {
      margin-top: 9px;
    }
  }

  .textFieldInput {
    height: 26px !important;
    padding: 0 6px !important;
  }
}