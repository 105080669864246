.neighborsTooltip {
  position: relative !important;
  width: 370px !important;
  border-radius: 14px !important;
  background-color: transparent;
  pointer-events: all !important;
  padding-top: 10px;

  .neighborsTooltipContent {
    padding: 15px 20px;

    .neighborsTooltipHeader {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #F4F4F4;
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 28px;

      svg {
        margin-right: 10px;
      }
    }

    .neighborsTooltipItemWrapper {
      max-height: 200px;
      overflow: auto;
      margin-top: 10px;

      .neighborsTooltipItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0.22px;
        line-height: 22px;
        padding: 5px 0;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          transition: background-color .2s ease-out;
          background-color: rgba(0, 0, 0, 0.04);
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        svg {
          margin-right: 10px;
        }

        .neighborsTooltipItemText {
          max-width: 285px;
        }

        .neighborsTooltipSitIcon {
          display: flex;
          align-items: center;
          border-radius: 8px;
          background-color: #F4F4F4;
          color: #5E5E5E;
          font-size: 14px;
          padding: 3px 5px;

          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
