.menuContainer {
  .menu {
    margin-top: 12px;
    padding: 16px 20px;
    border-radius: 14px;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15) !important;
  
    .checked {
      color: var(--primary);
    }
  }

  .buttonBlue {
    > div {
      color: #8e8fbd;
    }
  }

  .buttonDone {
    > div {
      padding: 4px 13px;
    }
  }
}

.deskTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.skeletonRoot {
  border-radius: 14px;
}

.bookingRightSide {
  max-width: 380px;
}

.instructionList {
  padding: 0 0 0 13px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 22px;

  li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.deskDetailsWrapper {
  padding-top: 10px;
  border-top: 1px solid var(--greyLight);
}

.customGroup {
  display: flex;

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.box {
  width: calc(60%);
}

.switchLabel {
  font-family: "Poppins";
  font-weight: 600;
}

.meetingRoomTitle {
  font-size: 14px;
  margin-bottom: 15px;
  letter-spacing: 0.22px;
  line-height: 28px;

  .meetingRoomLabel {
    margin-bottom: 5px;
  }

  .meetingRoomInput {
    width: 100%;
    border: none;
    padding: 7px 10px;
    border-radius: 6px;
    background-color: var(--greyLight);
    color: #000;
  }  
}

.btnMeetingSchedule {
  display: block !important;
  width: 100%;
}

.requiredSign {
  color: var(--warn);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 28px;
}

.teamMeetingObservation {
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 22px;
  color: var(--grey); 
  margin-left: 48px;
}

.boxRoomView {
  background-color: var(--greyLight);
  border-radius: 6px;
  padding: 4px 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

.boxRoomViewLabel {  
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  cursor: pointer;
  padding: 0px 8px; 
  text-align: center;
}

.boxRoomViewLabelSelected {  
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  color: #1E1F7B;
  background-color: white;
  border-radius: 6px;
  padding: 0px 8px;
  box-shadow: 1px 1px rgb(30 31 123 / 20%);
  cursor: pointer;
  transition: all 0.25s ease-in;
  text-align: center;
}