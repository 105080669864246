.modalInnerContent {  
  label {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-bottom: 7px;
  }

  .requiredSign {
    color: var(--warn);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
  }

  button.openButtonClassName {
    max-width: 370px;
    min-height: 36px !important;
    padding: 0 10px;
    border-radius: 6px;
    background-color: #F4F4F4;
  }
}

.formErrors {
  color: var(--warn);
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 22px;
  margin-top: 5px;
}

.formErrorShow {
  > div {
    background: var(--warnLight) !important;
  }
}
