.skeletonRoot {
  border-radius: 14px;
}

.floorItem {
  .buttonBase {
    width: 100%;
    border-radius: 14px;

    &:focus {
      box-shadow: 0 0 0 2px var(--primary);
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-radius: 14px;
    background-color: #f4f4f4;

    &.selected {
      background-color: var(--primaryLight);
    }
  }

  .itemContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
