.createSectionContainer {
  > div {
    &:first-child {
      border-radius: 14px;
    }
    
    &:nth-child(2) {
      display: none;
    }
  }

  .requiredField {
    label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.22px;
      line-height: 28px;
      margin-bottom: 7px;
    }

    .requiredSign {
      color: var(--warn);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.22px;
      line-height: 28px;
    }
  }
}