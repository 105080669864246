.locationSettingsContainer {
  > div {
    color: #5E5E5E;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    padding: 10px;
    border-bottom: 1px solid #F4F4F4;

    &:not(:last-child) {
      border-top: 1px solid #F4F4F4;
    }
  }

  .notificationSwitcher {
    width: 250px;
  }

  .dayListContainer {
    display: flex;
    align-items: center;

    .dayListContainerLabel {
      width: 250px;
      padding-right: 16px;
    }

    .select {
      > div {
        border-radius: 6px;
      }

      label {
        > div {
          font-size: 13px;
          font-weight: 400;
        }
      }

      .input {
        padding-left: 0;

        &:focus {
          background-color: #fff;
        }

        > div {
          color: #000000;
          font-size: 14px;
          letter-spacing: 0.22px;
          line-height: 22px;
        }
      }
    }
  }
}
