.reservationList {
  width: calc(100% - 400px);

  @media screen and (max-width: 1660px) {
    width: 100%;
  }

  .reservationTable {
    table-layout: auto;
  }

  .tableHead {
    .tableRow {
      background-color: #f4f4f4;

      .tableCell {
        padding: 2px 10px;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 400;
        font-family: "Poppins";

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .tableBody {
    .tableRow {
      &:hover {
        border: none;
      }

      td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      .tableCell {
        padding: 16px 10px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
        color: #000000;
        font-size: 14px;
        font-family: "Poppins";
      }

      .statusBooked {
        color: #5BC535;
      }

      .statusCancelled {
        color: var(--warn);
      }

      .statusPassed {
        color: #5E5E5E;
      }

      .statusPending {
        color: #5E5E5E;
      }
    }

    .pointerRow {
      cursor: pointer;
    }

    .checkedRow {
      background: var(--primaryLight);

      .tableCell {
        color: var(--primary);
      }
    }
  }

  // column sizes
  .listView {
    .allSelect {
      margin: 10px 0 -10px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;

      .allSelectActivate {
        color: var(--primary);
        font-weight: 600;
        cursor: pointer;
        margin: 0 5px;
      }
    }

    .tableHead, .tableBody {
      .tableRow {
        .tableCell {
          width: 16%;

          &:nth-child(1) {
            max-width: 130px;
          }

          &:nth-child(2) {
            max-width: 110px;
          }

          &:nth-child(3) {
            max-width: 150px;
          }

          &:nth-child(4) {
            max-width: 100px;
          }

          &:nth-child(5) {
            max-width: 200px;
          }

          &:nth-child(6) {
            max-width: 150px;
          }

          &:nth-child(7) {
            max-width: 100px;
          }

          &:nth-child(8) {
            max-width: 135px;
          }

          &:nth-child(9) {
            max-width: 90px;
          }

          &:last-child {
            max-width: 45px;
          }

        }
      }
    }
  }

  .mapView {
    .tableHead, .tableBody {
      .tableRow {
        .tableCell {
          width: 16%;

          &:nth-child(1) {
            min-width: 130px;
          }

          &:nth-child(2) {
            min-width: 110px;
          }

          &:nth-child(3) {
            min-width: 90px;
          }

          &:nth-child(4) {
            min-width: 110px;
          }

          &:nth-child(5) {
            min-width: 110px;
          }

          &:last-child {
            max-width: 45px;
          }

        }
      }
    }
  }
}

.reservationCalendar {
  display: block;

  @media screen and (max-width: 1660px) {
    display: none;
  }
}
