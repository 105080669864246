.attributes {
  .tableHead {
    .tableRow {
      background-color: #f4f4f4;

      .tableCell {
        padding: 2px 10px;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 400;

        &:first-child {
          width: 25%;
          min-width: 300px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .tableBody {
    .tableRow {
      .tableCell {
        padding: 6px 10px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
        color: #000000;
        font-size: 14px;

        &:first-child {
          width: 25%;
          min-width: 300px;
        }

        img {
          width: 16px;
          margin-right: 10px;
        }

        .mui4-MuiSwitch-colorPrimary.Mui-checked + .mui4-MuiSwitch-track {
          background-color: var(--primary);
        }
      }
    }
  }
}
