.header {
  padding-top: 20px;

  h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  nav.nav {
    ul {
      display: flex;
      margin-inline-start: 0;

      li {
        list-style-type: none;
        padding: 2px 10px;
        border-radius: 8px;
        background-color: #ffffff;
        transition: all .3s ease-out;

        &:not(:first-child) {
          margin-left: 10px;
        }

        &.isSelected {
          background-color: var(--primaryLight);
        }

        &:hover {
          color: #5E5E5E;
        }
      }
    }
  }
}
