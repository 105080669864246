.modalContent {
  font-size: 14px;

  .modalContentText {
    color: var(--grey);
  }

  .modalContentTextBold {
    color: #000;
    font-weight: bold;
  }
}
