.profileUserInfo {
  h3 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 28px;
  }

  .profileTooltipBlock {
    font-size: 14px;
    margin-bottom: 15px;
    letter-spacing: 0.22px;
    line-height: 28px;

    .profileTooltipTitle {
      margin-bottom: 5px;
    }

    .profileTooltipEmail {
      width: 100%;
      border: none;
      padding: 7px 10px;
      border-radius: 6px;
      background-color: rgba(244, 244, 244, 0.3);
      color: #000;
    }

    .profileTooltipPhone {
      width: 100%;
      border: none;
      padding: 7px 10px;
      border-radius: 6px;
      background-color: #f4f4f4;
      color: #000;
    }
  }

  .profileTooltipLink {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
  }
}

.selectWrapper {
  align-items: center;
  display: flex;
  background: #f4f4f4;
  width: 100%;
  border-radius: 6px;
}

.selectContainer {
  width: 100%;

  > div {
    padding-left: 10px;
  }
}
