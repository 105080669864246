.floorList {
  position: relative;

  .stickyButtonRow {
    position: sticky;
    bottom: 0;
    margin-top: 3px;
    padding: 17px 0 20px;
    background: #fff;
  }
}

.items {
  display: grid;
  gap: 15px;
}
