.header {
  padding-top: 20px;

  h3 {
    font-size: 22px;
  }

  .heading {
    position: relative;
    display: flex;
    align-items: center;

    .headingBack {
      position: absolute;
      left: -25px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  nav.nav {
    ul {
      display: flex;
      margin-inline-start: 0;

      li {
        list-style-type: none;
        padding: 2px 10px;
        border-radius: 8px;
        background-color: #ffffff;
        transition: all .3s ease-out;

        &:not(:first-child) {
          margin-left: 10px;
        }

        &.isSelected {
          background-color: var(--primaryLight);
        }

        &:hover {
          color: #5E5E5E;
        }
      }
    }
  }
}

.popoverContent {
  padding: 10px;
  border-radius: 14px;
  background-color: #ffffff;

  .popoverItemButton {
    justify-content: flex-start;
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    background-color: #fff;
    transition: all 0.1s ease-out;

    &:active,
    &:focus,
    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.searchInput {
  width: 230px !important;
}

.filtersButton {
  > div {
    height: 35px;
    padding: 6px 16px !important;
  }
}

.iconButton {
  background-color: var(--primary) !important;
  border-radius: 6px !important;
  padding: 6px !important;

  &:hover {
    background-color: var(--primaryHover) !important;
  }
}

.buttonWrapper {
  position: relative;
}

.allSelectedSize {
  > div {
    padding: 5px 45px 5px 9px !important;
  }
}
