.popover-content {
  padding: 10px;
  border-radius: 14px;
  background-color: #ffffff;

  .popover-item-button {
    justify-content: flex-start;
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    background-color: #fff;
    transition: all 0.1s ease-out;

    &:active,
    &:focus,
    &:hover {
      background-color: #f4f4f4;
    }
  }
}
