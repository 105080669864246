.dialogContainer {
  .dialog {
    max-width: 410px;
    padding: 20px;
    border-radius: 14px;
  }

  .inputLabel {
    cursor: pointer;
  }
}

.uploadFile {
  display: flex;
  align-items: center;
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: var(--primaryHover);
  }
}
