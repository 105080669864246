.calendar {
  .datetimeIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    padding: 7px;
    border-radius: 8px;
    background-color: #f4f4f4;

    img {
      width: 100%;
    }
  }

  .detailsHeader {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 28px;
    margin-bottom: 7px;
  }

  .floorDetailsContainer {
    max-width: 25%;

    .floorDetailsBlock {
      display: flex;
      align-items: center;
      padding: 2px 7px;
      border-radius: 8px;
      background-color: #f4f4f4;
      color: #5E5E5E;
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;

      .floorDetailsBlockOverflow {
        max-width: calc(100% - 21px)
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  .floorAddressContainer {
    max-width: calc(75% - 10px);
    display: flex;
    align-items: center;
    color: #5E5E5E;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;

    .floorAddressContainerOverflow {
      max-width: calc(100% - 21px)
    }

    svg {
      margin-right: 5px;
    }
  }

  .tabs {
    padding: 3px;
    min-height: 34px;
    border-radius: 6px;
    background-color: #f4f4f4;

    .scroller {
      overflow: visible !important;
    }

    .indicator {
      display: none;
    }

    .tab {
      min-height: auto;
      padding: 2px 12px;
      border-radius: 6px;
      color: #000;
      font-size: 14px;
      text-transform: initial;
      font-weight: 600;
      opacity: 1;

      @media (min-width: 600px) {
        min-width: 142px;
        width: 33%;
      }

      &.tabSelected {
        background-color: #ffffff;
        color: var(--primary);
        box-shadow: 0 3px 8px 0 rgba(30, 31, 123, 0.2);
      }
    }
  }
}
