.backButtonContainer {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;

  .arrowBackIcon {
    font-size: 30px;

    .arrowBackIconFill {
      fill: var(--primary);
    }
  }

  .root {
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 8px;
    background-color: var(--primaryLight);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
