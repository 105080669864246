.container {
  .tableHead {
    .tableRow {
      background-color: #f4f4f4;

      .tableCell {
        padding: 2px 10px;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 400;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .tableBody {
    .tableRow {
      cursor: pointer;

      &.inactive {
        .tableCell {
          color: var(--greyMedium);
        }
      }

      .tableCell {
        padding: 10px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
        color: #000000;
        font-size: 14px;
      }
    }
  }

  .tablePagination {
    border: none;
  }

  .statusBadge {
    display: inline-block;
    padding: 3px 10px;
    border-radius: 8px;
    color: #fff;

    &.pending {
      background-color: var(--greyMedium);
    }
    &.sent {
      background-color: var(--primary);
    }
  }
}
