.floors {
  .tableHead {
    .tableRow {
      background-color: #f4f4f4;

      .tableCell {
        width: 16%;
        padding: 2px 10px;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 400;
        font-family: "Poppins";

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          width: 4%;
        }

        &:nth-child(1) {
          width: 18%;
        }

        &:nth-child(4) {
          width: 18%;
        }

        &:nth-child(6) {
          width: 16%;
        }

        &:nth-child(7) {
          width: 8%;
        }

        &:nth-child(8) {
          width: 8%;
        }
      }
    }
  }

  .tableBody {
    .tableRow {
      cursor: pointer;

      &.disabled {
        .tableCell {
          color: var(--greyMedium);
        }
      }

      .tableCell {
        width: 16%;
        padding: 12px 10px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
        color: #000000;
        font-size: 14px;
        font-family: "Poppins";

        &:nth-child(1) {
          width: 18%;
        }

        &:nth-child(4) {
          width: 18%;
        }

        &:nth-child(6) {
          width: 16%;
        }

        &:nth-child(7) {
          width: 8%;
        }

        &:nth-child(8) {
          width: 8%;
        }

        &:last-child {
          width: 8%;
        }
      }
    }
  }
}

.locationTable {
  table-layout: fixed;
}

.tooltip {
  padding: 8px 14px;
  border-radius: 6px;
  background-color: #000000;
  font-size: 14px;
}
