.popupContainer {
  > div {
    &:first-child {
      border-radius: 10px;
    }
    
    &:nth-child(2) {
      display: none;
    }

    > div {
      margin: 5px;
      width: initial !important;
    }
  }
}