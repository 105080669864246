.container {
  padding: 0 50px;
}


@media screen and (max-width: 770px) {
  .container {
    padding: 0 5px;
  }
}
