.hourSlider {
  .slider {
    color: #d8d8d8;

    .rail {
      top: 50%;
      transform: translateY(-50%);
      height: 4px;
      border-radius: 2px;
    }

    .track {
      top: 50%;
      transform: translateY(-50%);
      height: 4px;
      background-color: var(--primary);
    }

    .mark {
      background-color: #d8d8d8;

      &.markActive {
        background-color: var(--primary);
      }
    }

    .markLabel {
      top: 33px;
      color: #ababab;
      font-size: 13px;
    }

    .thumb {
      top: 50%;
      transform: translateY(calc(-50% + 5px));
      height: 24px;
      width: 24px;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 3px 8px 0 rgba(30, 31, 123, 0.2);
    }
  }
}
