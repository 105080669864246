.menuContainer {
  .menu {
    width: 370px;
    margin-top: 12px;
    padding: 16px 20px;
    border-radius: 14px;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15) !important;

    .filtersFormControlWrapper {
      margin-top: 7px;
      max-height: 180px;
      overflow: auto;

      label {
        margin: 0;
        padding: 6px 5px;
        height: 34px;
        border-radius: 6px;
        transition: background-color .3s ease-out;

        &:hover {
          background-color: #d7dee9;
        }

        > span {
          margin-right: 7px;
          font-size: 14px;
          letter-spacing: 0.22px;
          line-height: 22px;
        }
      }

      .bookedStyle {
        color: #5BC535;
      }

      .pendingStyle {
        color: #1E1F7B;
      }

      .cancelledStyle {
        color: #FF4C0E;
      }
    }

    .checkedLabel {
      background-color: #EFEFF8;
    }

    .checked {
      color: var(--primary);
    }
  }

  .buttonDone {
    > div {
      padding: 4px 13px;
    }
  }
}

.filtersCounter {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  background-color: var(--primaryLight);;
}
