.groups {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.accordion {
  width: 100%;
  margin-top: 0 !important;
  border-radius: 6px;
  background-color: #FFFFFF !important;
  box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15) !important;

  &:before {
    display: none;
  }

  &.accordionExpanded {
    margin: 5px 0 0;

    .accordionSummaryIcon {
      transform: rotate(-90deg);
    }
  }

  .accordionSummary {
    display: none;

    &.accordionSummaryActive {
      background-color: #e3eef8;

      .accordionSummaryIcon {
        margin-right: 0;
        padding: 0;

        svg {
          color: var(--primary) !important;
        }
      }
    }

    .accordionSummaryContent {
      min-height: 30px;
      display: flex;
      align-items: center;
      margin: 0;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .accordionSummaryIcon {
      margin-right: 0;
      margin-left: 5px;
      padding: 0;

      svg {
        width: 20px;
      }
    }
  }

  .accordionDetails {
    padding: 0;
    color: #5E5E5E;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  .ownerSearch {
    margin-top: 5px !important;

    input {
      text-overflow: ellipsis;
    }
  }

  .expandIcon {
    cursor: pointer;
  }

  .expandIconOpen {
    transform: rotate(-180deg);
  }
}

.accordionCounter {
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  border-radius: 8px;
  margin-right: 5px;
  background-color: var(--primaryLight);
  cursor: pointer;

  &:hover {
    background-image: url("GroupSearch/assets/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
  }
}

.filtersFormControlWrapper {
  width: 100%;

  label {
    margin: 0;
    padding: 6px 7px;
    height: 34px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: #F4F4F4;
    }

    > span {
      display: grid;
      margin-right: 7px;
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;
    }
  }

  .checkedLabel {
    background-color: #EFEFF8;
  }

  .checked {
    color: var(--primary);
  }
}