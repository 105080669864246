%input {
  min-height: 36px;
  padding: 0 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #5e5e5e;
    opacity: 0.9;
  }
}

.textField {
  border-radius: 6px;
  background-color: #f4f4f4;
  font-family: "Poppins", sans-serif;

  &.focused {
    background-color: var(--primaryLight);
  }

  &.error {
    background: var(--warnLight);;
  }

  .input {
    @extend %input;
  }

  .inputPassword {
    @extend %input;
    font-size: 34px;

    &:placeholder-shown {
      font-size: 14px;
    }
  }
}

.inputLabel {
  cursor: pointer;
}

.helperText {
  margin-top: 6px;
  font-size: 14px;

  &.error {
    color: var(--warn);
  }
}