.customGroup {
  display: flex;
  flex-direction: column;
  line-height: 30px;

  .groupTitle {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.customItem {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.timer {
  display: flex;
  flex-direction: column;
  width: 30px;
  align-items: center;
  min-width: 180px;
}

.line {
  height: 63px;
}

.group {
  background-color: #F4F4F4;
  border-radius: 14px;
  padding: 5px 10px;
  margin-right: 10px;
  max-width: 180px;
}

.groupSelected {
  @extend .group;
  background-color: #EFEFF8;
  
  & > div, p {
    color: #1E1F7B;    
  }
}


.hours {  
  display: flex;    
  width: 100vh;
}

.currentDay {
  color: #1E1F7B
};

.selectedDay {
  color: #1E1F7B !important;
  background: #EFEFF8;
  padding: 0px 22px;
  border-radius: 6px;
  font-weight: bold !important; 
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  padding-top: 10px;
}

.selectableGroup {
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.blocks {
  display: flex;
  position: relative;
  width: 100vh;
  height: 63px;
}

.block {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  min-width: 45px;
  max-width: 45px;
  cursor: pointer;  

  &:hover {
    background-color: #EFEFF8;
    border-radius: 6px;
  }
}

.blockSelected {
  min-width: 45px;
  max-width: 45px;
  cursor: pointer;
  background-color: #EFEFF8; 
}

.blockFirstSelected {
  @extend .blockSelected;
  border-radius: 6px 0px 0px 6px; 
}

.blockLastSelected {
  @extend .blockSelected;
  border-radius: 0px 6px 6px 0px; 
}

.blockScheduled {
  width: 100%;
  min-width: 45px;
  max-width: 45px;
  background-color: #FFE4DB;    
}

.blockFirstScheduled {
  @extend .blockScheduled;
  border-radius: 6px 0px 0px 6px;  
}

.blockLastScheduled {  
  @extend .blockScheduled;
  border-radius: 0px 6px 6px 0px;  
}

.blockMeeting {
  @extend .blockScheduled;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockDisabled {
  @extend .blockScheduled;
  background-color: #eee;
  border-radius: 6px;
}

.list {
  height: 60vh;
  overflow-y: scroll;
}

.calendar {
  overflow: hidden;
}

.headerText {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  line-height: 28px !important;
  color: #ABABAB !important;
  margin-bottom: 5px;
}

.dateSelector {  
  min-width: 180px;
  padding-right: 5px;
}

.dateRange {
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
}

.neighbors {
  align-self: center;
  margin-right: 5px;
}

.info {
  align-self: center;
}