.profileSupportLinks {
  h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.29px;
  }

  .profileSupportBlock {
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 10px;
      border-radius: 6px;
      background-color: var(--primaryLight);
      color: var(--primary);
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;
      outline: none;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .line {
    height: 1px;
    width: 20px;
    background-color: var(--primary);
  }
}
