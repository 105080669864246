.htmlEditor {
  .formErrors {
    color: var(--warn);
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-top: 5px;
  }

  .formErrorShow {
    background: var(--warnLight) !important;
  }

  .editorWrapper {
    display: flex;
    flex-direction: column;
    min-height: 106px;
    padding: 4px;
    border-radius: 6px;
    background-color: var(--greyLight);
  }

  .editor {
    flex: 1;
    padding: 6px;
    cursor: text;
  }

  .toolbar {
    padding: 4px 5px;
    border-radius: 6px;

    > div {
      margin-bottom: 0;
    }
  }
}
