.textarea {
  .mui4-MuiOutlinedInput-multiline {
    padding: 7px 10px;
    border-radius: 6px;
  }

  &--default {
    background: #F4F4F4;
    outline: none;
    border-radius: 6px;
    transition: all .3s ease-out;

    &:hover {
      background: #EDEDED;
    }

    .mui4-MuiInputBase-root {
      transition: all .3s ease-out;
    }

    .mui4-MuiOutlinedInput-notchedOutline {
      border: 1px solid transparent !important;
    }

    .Mui-focused {
      background: var(--primaryLight);
    }
  }
}

.input {
  height: 34px;
  font-family: "Poppins", sans-serif;

  & > div {
    height: 100%;
  }

  input {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  .mui4-MuiInput-underline:after {
    border: none !important;
  }

  .mui4-MuiInput-underline {
    &:before,
    &:after {
      content: none;
    }
  }

  .Mui-focused {
    background: var(--primaryLight);
    border-radius: 6px;
  }

  .mui4-MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 65px 0 0;

    input {
      padding: 7px 10px;
    }

    .mui4-MuiIconButton-root {
      width: 28px;
      height: 28px;
    }
  }

  &--default {
    background: #F4F4F4;
    outline: none;
    border-radius: 6px;
    transition: all .3s ease-out;

    &:hover {
      background: #EDEDED;
    }

    .mui4-MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .Mui-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &--withError {
    height: auto;

    > div {
      background: var(--warnLight) !important;
    }
  }

  &--inline {
    input {
      padding: 7px 10px;
    }
  }

  &--search {
    .mui4-MuiOutlinedInput-adornedStart {
      padding-left: 6px;
    }

    input {
      padding: 7px 4px 7px 7px;
    }
  }
}

.switcher {
  .mui4-MuiSwitch-root {
    height: 36px;
  }

  .mui4-MuiIconButton-root {
    border-radius: 30%;
  }

  .mui4-MuiSwitch-switchBase {
    position: absolute;
    right: 20px;
    top: 5px;

    &.Mui-checked {
      transform: translateX(16px);
    }
  }

  .mui4-MuiSwitch-track {
    width: 30px;
    height: 14px;
  }

  .mui4-MuiSwitch-thumb {
    width: 10px;
    height: 10px;
    border-radius: 3px;
  }

  .mui4-MuiSwitch-track {
    border-radius: 4px;
  }

  .mui4-MuiSwitch-colorSecondary.Mui-checked {
    color: #fff;

    + .mui4-MuiSwitch-track {
      background-color: var(--primary);
      opacity: 1;
      border-radius: 4px;
    }

    &.Mui-disabled {
      + .mui4-MuiSwitch-track {
        opacity: .5;
      }
    }
  }

  &--thin {
    &.mui4-MuiSwitch-root {
      width: auto;
      height: auto;
      padding: 0;
    }

    .mui4-MuiSwitch-switchBase {
      padding: 0;
      right: 16px;
      top: 2px;
    }
  }
}

.react-select-custom {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 22px;

  .select-custom-multi__placeholder {
    color: #b5b5b5;
  }

  .select-custom__control,
  .select-custom-multi__control {
    border: none;
    box-shadow: none;
    min-height: 33px;
    background: #F4F4F4;
    transition: all .3s ease-out;

    &:hover {
      background: #EDEDED;
    }

    &--is-focused {
      background: #EFEFF8 !important;
      box-shadow: 0 0 0 1px var(--primaryHover), 0 0 0 var(--primaryHover);
    }
  }

  .select-custom-multi__control {
    max-width: 370px;
    border-color: #F4F4F4 !important;
    box-shadow: none;
  }

  .select-custom__value-container,
  .select-custom-multi__value-container {
    padding: 0 8px;
  }

  .select-custom-multi__value-container {
    .select-custom-multi__multi-value {
      .select-custom-multi__multi-value__label {
        padding: 2px 5px;
        margin-top: 1px;
        font-size: 14px;
      }

      //.select-custom-multi__multi-value__remove {
      //  display: none;
      //}
    }
  }

  .select-custom__indicator-separator,
  .select-custom-multi__indicator-separator {
    display: none;
  }

  .select-custom__menu {
    margin-top: 1px;
    box-shadow: 0 0 0 1px var(--primaryHover), 0 0 0 var(--primaryHover);

    .select-custom__menu-list {
      padding: 0;

      .select-custom__option {
        padding: 5px 12px;
        min-height: 33px;
        border-radius: 6px;
        cursor: pointer;
        transition: all .3s ease-out;

        &--is-focused {
          background: #EFEFF8;
          color: var(--primary);
        }

        &--is-selected {
          background: var(--primaryHover);
          color: #fff;
        }
      }
    }
  }

  .select-custom-multi__menu {
    margin-top: 1px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.15);

    .select-custom-multi__menu-list {
      padding: 0;

      .select-custom-multi__option {
        padding: 5px 12px;
        min-height: 33px;
        border-radius: 6px;
        cursor: pointer;
        transition: all .3s ease-out;

        &--is-selected {
          background: #fff;
          color: #000;
        }

        &--is-focused {
          background: #EFEFF8;
          color: #000;
        }
      }
    }
  }
}

.mui4-MuiAutocomplete-popper {
  .mui4-MuiAutocomplete-paper {
    margin-top: 0;

    .mui4-MuiAutocomplete-listbox {
      padding: 0;
      font-size: 14px;
    }
  }
}
