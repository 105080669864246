.deskDetails {
  .detailsItem {
    display: grid;
    grid-template-columns: 130px 240px;
    gap: 20px;
    align-items: baseline;

    &:not(:first-child) {
      margin-top: 9px;
    }
  }

  .selectFullWidth {
    width: 100%;
  }

  .selectLabel {
    margin: 0;
  }

  .textFieldInput {
    height: 26px !important;
    padding: 0 6px !important;
  }
}