.filtersFormControlWrapper {
  width: 100%;

  label {
    margin: 0;
    padding: 6px 7px;
    height: 34px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: #F4F4F4;
    }

    > span {
      display: grid;
      margin-right: 7px;
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;
    }
  }

  .checkedLabel {
    background-color: #EFEFF8;
  }

  .checked {
    color: var(--primary);
  }
}
