.notification {
  .notificationAction {
    margin-right: 5px;
    transition: all .3s ease-out;
    opacity: 0;
  }

  &:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F4F4F4;
  }

  &:hover {
    .notificationAction {
      opacity: 1;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 28px;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 28px;
  }

  .notificationHeader {
    h3 {
      margin-right: 10px;
    }

    img {
      position: absolute;
      left: -11px;
      top: 1px;
    }

    svg {
      cursor: pointer;

      .fillColor {
        transition: all .3s ease-out;
      }

      &:hover {
        .fillColor {
          fill: var(--primaryLighten20);
        }
      }
    }
  }

  .notificationTextBlock {
    margin-bottom: 10px;
  }

  .notificationText {
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  .notificationActionButton {
    color: var(--primary);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.22px;
    line-height: 28px;
    margin-left: 10px;
  }

  .notificationStatusDate {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  .notificationStatus {
    color: #fff;
    padding: 2px 7px;
    border-radius: 8px;
    background-color: var(--primary);
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0.22px;
    line-height: 22px;
    margin-left: 15px;
  }

  .notificationStatusPending {
    background: #ABABAB;
  }
}