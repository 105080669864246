.admin-calendar {
  display: block !important;
  max-width: 1340px;

  .DayPicker-Months {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    .DayPicker-Month {
      padding: 0;
      margin: 0;

      .DayPicker-Caption {
        padding: 0 0.7em;
        margin-bottom: 0;

        > div {
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.22px;
          line-height: 28px;
        }
      }

      .DayPicker-Weekdays {
        display: block;

        .DayPicker-WeekdaysRow {
          display: flex;
          justify-content: space-around;

          .DayPicker-Weekday {
            display: block;
            width: 40px;
            color: #ABABAB;
            font-size: 13px;
            letter-spacing: 0.21px;
            line-height: 28px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }

      .DayPicker-Body {
        display: block;

        .DayPicker-Week {
          display: flex;
          justify-content: space-around;
          margin-bottom: 5px;
          width: 100%;

          .DayPicker-Day {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 10px;
            color: #000000;
            font-size: 16px;

            &--today {
              color: #000000;
              font-weight: 400;
            }

            &--disabled {
              cursor: pointer;
              color: var(--warn);
              background: var(--warnLight);;
            }

            &--outside {
              color: transparent;
              background: transparent;
              cursor: default;
            }
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            color: var(--primary);
            background: rgb(239, 239, 248);
            font-weight: 700;
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
            background-color: #d7d7f0;
          }
        }
      }
    }
  }
}

.custom-calendar {
  .rdrMonthAndYearWrapper {
    position: relative;
    justify-content: flex-start;

    .rdrMonthAndYearPickers {
      flex: none;
      order: 1;
    }

    .rdrPprevButton, .rdrNextButton {
      position: absolute;
      width: 10px;
      height: 18px;

      i {
        display: none;
      }
    }

    .rdrPprevButton {
      order: 2;
      right: 46px;
      background: url('../Admin/Pages/Reservations/assets/arrow-left.svg') no-repeat;
    }

    .rdrNextButton {
      right: 0;
      background: url('../Admin/Pages/Reservations/assets/arrow-right.svg') no-repeat;
    }
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrWeekDays {
    .rdrWeekDay {
      text-transform: uppercase;
    }
  }
}