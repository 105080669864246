.container {
  position: relative;
  min-height: 100vh;

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: -1;

    .backgroundImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  .textBlock {
    color: #5e5e5e;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  .selectContainer {
    margin: 2px 0 0 -5px;
  }

  .selectLabel {
    margin-bottom: 3px;
  }

  .button {
    div {
      display: flex;
      align-items: center;
    }
  }
}
