.container {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin: 0 -3px;

  > * {
    margin: 3px;
  }

  button.timeSlot {
    padding: 2px 8px;
    border-radius: 6px;
    background-color: #f4f4f4;

    &.isSelected {
      background-color: var(--primaryLight);
    }
  }
}
