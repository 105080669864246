.listViewItem {
  background-color: var(--greyLight);
  border-radius: 14px;
  padding: 10px 15px 10px 10px;  
}

.listViewItemAttendees {
  color: var(--grey);
  line-height: 22px;
  font-size: 14px;   
}

.listViewItemAttendeesSelected {
  color: #1E1F7B !important;
  line-height: 22px;
  font-size: 14px;   
}

.listViewItemSelected {
  background-color: #EFEFF8;
  border-radius: 14px;
  padding: 10px 15px 10px 10px;  
}

.listViewItemText {
  color: black;
}

.listViewItemTextSelected {
  color: #1E1F7B !important;
}

.listViewItemLightText {
  color: var(--grey);
}