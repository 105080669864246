.popupContainer {
  white-space: nowrap;

  > div {
    &:first-child {
      border-radius: 20px;
      padding: 0;
    }

    &:nth-child(2) {
      display: none;
    }

    > div {
      margin: 0;
      width: initial !important;
    }
  }
}

.items {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: 0 -5px;

  > .item {
    margin: 4px 5px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f4f4f4;
  }
}
