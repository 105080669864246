nav.nav {
  ul {
    display: flex;
    margin-inline-start: 0;

    li {
      list-style-type: none;
      padding: 2px 10px;
      border-radius: 8px;
      background-color: #ffffff;

      &:not(:first-child) {
        margin-left: 10px;
      }

      &.isSelected {
        background-color: var(--primaryLight);
      }
    }
  }
}