.profileWrapper {
  display: flex;
  align-items: center;

  .profileGreeting {
    margin-left: 10px;

    .profileWelcome {
      color: #000000;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.35px;
      line-height: 28px;
    }

    .delegateTrigger {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-left: 5px;

        &.open {
          transform: rotate(-180deg);
        }
      }
    }

    .profileName {
      display: flex;
      align-items: center;
      max-width: 250px;
      color: #5e5e5e;
      font-size: 14px;
      font-weight: 600;

      &.executiveAssistant {
        display: inline-flex;
        border-radius: 4px;
        background-color: #FF4C0E;
        color: #FFFFFF;
        font-size: 10px;
        letter-spacing: 0.16px;
        line-height: 14px;
        font-weight: 400;
        padding: 1px 4px;
      }
    }
  }

  .profileImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border-radius: 8px;
    background: var(--primary);

    &.clickableProfileImage {
      cursor: pointer;

      &:hover {
        background: var(--primaryHover);
      }
    }
  }

  &.isAdmin {
    .profileGreeting {
      margin-left: 5px;

      .profileWelcome {
        color: var(--color-grey);
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }

      .profileName {
        max-width: 170px;
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
      }
    }

    .profileImage {
      height: 30px;
      width: 30px;
    }
  }
}

.profileTooltip {
  min-width: 310px;
  padding: 20px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15);

  h3 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 28px;
  }

  .profileTooltipBlock {
    font-size: 14px;
    margin-bottom: 15px;
    letter-spacing: 0.22px;
    line-height: 28px;

    .profileTooltipTitle {
      margin-bottom: 5px;
    }

    .profileTooltipEmail {
      width: 100%;
      border: none;
      padding: 7px 10px;
      border-radius: 6px;
      background-color: rgba(244, 244, 244, 0.3);
      color: #000;
    }

    .profileTooltipPhone {
      width: 100%;
      border: none;
      padding: 7px 10px;
      border-radius: 6px;
      background-color: #f4f4f4;
      color: #000;
    }
  }

  .profileTooltipLink {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
  }
}

.popoverContent {
  font-size: 14px;
  padding: 5px 10px 10px;
  border-radius: 14px;
  background-color: #ffffff;

  .delegateModalTitle {
    font-size: 13px;
  }

  .delegateModalButton {
    > div {
      font-size: 13px;
    }
  }
}

.selectWrapper {
  align-items: center;
  display: flex;
  background: #f4f4f4;
  width: 100%;
  border-radius: 6px;
}

.selectContainer {
  width: 100%;

  > div {
    padding-left: 10px;
  }
}
