.detailsInfoBlock {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .fieldLabel {
    font-size: 14px;
    margin-bottom: 7px;
  }

  .requiredSign {
    color: var(--warn);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 28px;
  }

  .disabledField {
    opacity: .5;
  }
}

button.openButtonClassName {
  min-height: 36px !important;
  padding: 0 10px;
  border-radius: 6px;
  background-color: #F4F4F4;
}
