.rdrCalendarWrapper {
  width: 100%;

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: var(--primary);
    font-weight: 700;
  }

  .rdrDayNumber span {
    font-size: 16px;
  }

  .rdrStartEdge {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .rdrEndEdge {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .rdrDay {
    height: 36px;
    padding: 23px 0;
  }

  .rdrWeekDay {
    padding: 5px 0;
    color: #ababab;
    font-size: 13px;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: var(--primary) !important;
  }

  .rdrMonthAndYearPickers {
    font-size: 14px;
  }

  .rdrMonths {
    width: 100%;
  }

  .rdrMonth {
    width: 100%;
    padding: 0 0 1.666em;
  }

  .rdrNextPrevButton {
    margin: 0;
  }

  .rdrSelected {
    background-color: var(--primaryLight);
  }
}
