.arrowContainer {
  display: flex;
  align-items: center;
  margin-inline-start: 5px;

  .arrow {
    fill: var(--greyMedium);

    &.active {
      fill: var(--primary);
    }

    &.completed {
      fill: var(--primary);
    }
  }
}
