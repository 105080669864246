.groupDetails {
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .documentsWrapper {
    width: calc(100% - 300px);
  }
}
