::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #F4F4F4;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #ABABAB;
}
