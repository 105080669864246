.items {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: 0 -5px;

  > .item {
    margin: 4px 5px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f4f4f4;
  }
}
