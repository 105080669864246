.settingForm {
  padding: 15px 0;
  border-bottom: 1px solid var(--greyLight);
  font-size: 14px;
  max-height: 55px;

  &:first-child {
    border-top: 1px solid var(--greyLight);
  }

  .settingFormText {
    .settingFormLabel {
      color: var(--grey);
      margin-right: 3px;
    }

    .settingFormValue {
      color: #000000;
    }
  }

  .settingFormInput {
    input {
      margin-left: 5px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0.22px;
      line-height: 22px;
    }
  }

  .editSetting {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px 10px;
    cursor: pointer;

    .fillColor {
      transition: all .3s ease-out;
    }

    &:hover {
      .fillColor {
        fill: var(--primaryLighten20);
      }
    }
  }
}
