.searchInput {
  input {
    padding: 7px 10px 7px 5px !important;
  }
}

.formErrorShow {
  > div {
    background: var(--warnLight) !important;
    > div {
      background: var(--warnLight) !important;
    }
  }
}

.inputPropsWrapper {
  position: relative;

  .inputPropsIcon {
    position: absolute;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    margin-left: 5px;
  }
}

.hasValue {
  input {
    padding-left: 33px !important;
  }
}
