.tooltipContent {
  font-size: 14px;
  font-weight: 400;
}

.tooltipContentBox {
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  color: #5E5E5E;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.21px;
  text-align: left;
  line-height: 22px;

  svg {
    margin-right: 5px;
  }
}
