.filtersCounter {
  cursor: pointer;
  background-image: url("assets/close.svg"); // load the image instantly (not after hover)
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;

  &:hover {
    background-size: auto;

    > div {
      color: transparent;
    }
  }
}
